<template>
  <div class="content-box">
    <div class="content-header">
        <el-button @click="handleEdit({ status: true, ysbState: true })" type="plain" class="cotent-btn-big" icon="el-icon-plus">新增</el-button>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
        v-loading="loading">
        <el-table-column prop="nav1Value" align="center" label="一级导航">
        </el-table-column>
        <el-table-column prop="nav2Value" align="center" label="二级导航">
        </el-table-column>
        <el-table-column prop="title" align="center" label="标题">
        </el-table-column>
        <el-table-column prop="logoUrl" align="center" label="logo">
            <template #default="{row}">
                <el-image
                    style="width: 100px; height: 100px"
                    :src="row.logoUrl"
                    :zoom-rate="1.2"
                    :preview-src-list="[row.logoUrl]"
                    :initial-index="0"
                    fit="cover"
                />
            </template>
        </el-table-column>
        <el-table-column prop="sort" align="center" label="排序">
        </el-table-column>
        <el-table-column align="center" label="外显应用">
            <template #default="{ row }">
                <span v-if="row.ysbState">养身帮小程序</br></span>
                <span v-if="row.zyState">智养小程序</span>
                <span v-if="!row.ysbState && !row.zyState">-</span>
            </template>
        </el-table-column>
        <el-table-column prop="loginState" align="center" label="开启注册">
            <template #default="{row}">
                <span class="enable-btn" v-if="row.loginState == 0">关闭</span>
                <span class="disable-btn" v-if="row.loginState == 1">开启</span>
            </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="发布状态">
            <template #default="{row}">
                <span class="enable-btn" v-if="row.status == 0">禁用</span>
                <span class="disable-btn" v-if="row.status == 1">启用</span>
            </template>
        </el-table-column>
        <el-table-column prop="modifiedBy" align="center" label="操作人">
        </el-table-column>
        <el-table-column prop="modifiedTime" align="center" label="操作时间">
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
            <template #default="{ row }">
                <el-button type="text" @click="handleEdit(row)">编辑</el-button>
                <el-button type="text" @click="deleteRow(row)">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="800px" :before-close="dialogClose" :close-on-click-modal="false">
        <div class="edit-box">
            <el-form label-position="left" :inline="true" label-suffix=":" label-width="90px" :model="formData" :rules="formRules" ref="validateForm">
                <el-form-item label="一级导航" prop="nav1Key">
                    <el-select style="width: 260px" disabled v-model="formData.nav1Key" placeholder="请选择">
                        <el-option
                            v-for="item in firstNavOptions"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级导航" prop="nav2Key">
                    <el-select style="width: 260px" disabled v-model="formData.nav2Key" placeholder="请选择">
                        <el-option
                            v-for="item in secondNavOptions"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input style="width: 260px" v-model="formData.title" class="dialogInput" placeholder="" maxlength="4">
                    </el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input-number v-model="formData.sort" align="left" :controls="false" :precision="0" 
                        placeholder="数字越小越靠前"
                        style="width: 260px;text-align: left" :min="0" :max="999"></el-input-number>
                </el-form-item>
                <el-form-item style="width: 100%" label="跳转地址" prop="jumpPath">
                    <el-input v-model="formData.jumpPath" class="dialogInput" style="width: 620px" placeholder="">
                    </el-input>
                    <p class="tip">新增或修改跳转地址后需联系管理员在小程序控制台配置对应地址的白名单</p>
                </el-form-item>
                <el-form-item style="width: 100%" label="logo上传" prop="logoUrl">
                    <el-upload
                    :file-list="fileList"
                    class="avatar-uploader"
                    :action="uploadImageUrl"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :class="{ 'hide-upload-btn': !!formData.logoUrl }"
                    :limit="1">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p class="tip">只能上传jpg/png格式文件,大小≤0.5M</p>
                </el-form-item>
                <el-form-item style="width: 100%" label="外显应用">
                    <el-checkbox v-model="formData.ysbState">养身帮小程序</el-checkbox>
                    <el-checkbox v-model="formData.zyState">智养小程序</el-checkbox>
                </el-form-item>
                <el-form-item style="width: 100%" label="开启注册" prop="loginState">
                    <el-switch
                        v-model="formData.loginState"
                        active-color="#13ce66"
                        >
                     </el-switch>
                </el-form-item>
                <el-form-item label="发布状态" prop="status">
                    <el-switch
                        v-model="formData.status"
                        active-color="#13ce66"
                        >
                     </el-switch>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input :rows="3" v-model="formData.remark" type="textarea" style="width: 620px" placeholder="" maxlength="225">
                    </el-input>
                </el-form-item>
            </el-form>
            
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogClose">取消</el-button>
                <el-button :loading="saveLoading" type="primary" @click="dialogConfirm">提交</el-button>
            </div>
        </div>
    </el-dialog>
    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogPreviewVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import UploadImg from "@/components/uploadImg.vue";

import config from "@/config/default";

import { getMIniPer, miniComposeSaveOrUpdate, miniComposeRm, getNavList as getNavListApi } from "@/api/mini";

import { cloneDeep } from "lodash";
import { urlReg } from "@/utils/regular";

@Component({
    components: {
        UploadImg
    }
})
export default class Mini extends Mixins(loading, tablePagination, Resize) {
    tableData = [];
    itemTotal = 0;

    firstNavOptions = []; // 一级导航
    secondNavOptions = []; // 二级导航

    dialogVisible = false;
    dialogTitle = "新增小程序排版";
    saveLoading = false;

    formData = {
        id: 0,
        jumpPath: "",
        logoUrl: "",
        nav1Key: "mini_compose_member",
        nav1Value: "",
        nav2Key: "mini_compose_member_enter",
        nav2Value: "",
        remark: "",
        sort: undefined,
        title: "",
        loginState: false,
        status: true,
        ysbState: true,
        zyState: false
    };

    formRules = {
        nav1Key: { required: true, message: "请选择一级导航", trigger: "blur" },
        nav2Key: { required: true, message: "请选择二级导航", trigger: "blur" },
        title: { required: true, message: "请输入标题", trigger: "blur" },
        sort: { required: true, message: "请输入排序", trigger: "blur" },
        jumpPath: { required: true, validator: this.validatorUrl, message: "请输入跳转地址", trigger: "blur" },
        logoUrl: { required: true, message: "请上传logo", trigger: "blur" },
    }

    uploadImageUrl = `${config.uploadImg}permutation`

    fileList = [];
    fileList2 = [];

    dialogImageUrl = ""; // 图片预览
    dialogPreviewVisible = false;

    mounted() {
        this.windowResize(310)
        this.getData();
        // 初始默认小程序、个人中心
        this.getFirstNavList("app_mini_compose");
        this.getSecondNavList("mini_compose_member")
    }

    validatorUrl(rule, value, callback) {
        // H5: http(s)://xxx.xxx
        // 小程序内跳转: wx://xxx/xxx/xxxx
        // 三方小程序: mini://{appId}/{path}?{extraData}
        const reg = /(http|https|wx|mini):\/\/S*/;
        reg.test(value) ? callback() : callback(new Error())
    }

    getData() {
        this.showLoading();
        getMIniPer({
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.tableData = res.data;
        }).finally(() => {
            this.hideLoading();
        });
    }

    getFirstNavList(parentKey) {
        getNavListApi(parentKey).then((res) => {
            this.firstNavOptions = res.data || [];
        })
    }

    getSecondNavList(parentKey) {
        getNavListApi(parentKey).then((res) => {
            this.secondNavOptions = res.data || [];
        })
    }

    // ======图片相关======
    handleAvatarSuccess(res, file) {
        this.formData.logoUrl = `${config.uploadImgUrl}${res.data.url}`;
        this.fileList = [{ url: this.formData.logoUrl }]
    }
    beforeAvatarUpload(file) {
        const isJPG = ['image/png', 'image/jpeg'].includes(file.type);
        const isLtM = (file.size / 1024 / 1024) <= 0.5;
        if (!isJPG) {
            this.$message.error('图片只能是jpg/png格式!');
        }
        if (!isLtM) {
            this.$message.error('图片大小不能超过0.5M!');
        }
        return isJPG && isLtM;
    }

    handleRemove(file, fileList) {
        this.formData.logoUrl = "";
        this.fileList = [];
    }
    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogPreviewVisible = true;
    }
  // ======图片相关======

  handleEdit(row = {}) {
    this.dialogVisible = true;
    this.$nextTick(() => {
        this.$refs['validateForm'].resetFields();
        this.formData.id = "";
        this.formData.loginState = false;
        this.formData.status = true;
        this.formData.ysbState = true;
        Object.assign(this.formData, cloneDeep(row), { loginState: !!row.loginState, status: !!row.status, ysbState: !!row.ysbState, zyState: !!row.zyState })
        this.fileList = row.logoUrl ? [{ url: row.logoUrl }] : [];
        this.dialogTitle = `${row.id ? "编辑" : "新增" }小程序排版`;
    })
  }

  deleteRow(row) {
    if (row.status) {
        this.$message.warning("开启状态，无法删除");
        return;
    }
    this.$confirm(`是否删除排版【${row.title}】`, '提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
    }).then(() => {
        miniComposeRm(row.id).then(() => {
            this.$message.success("操作成功");
            this.getData();
        })
    })
  }

  dialogConfirm() {
    this.$refs['validateForm']?.validate().then(() => {
        const nav1Value = this.firstNavOptions.find((el) => el.dictKey == this.formData.nav1Key)?.dictValue;
        const nav2Value = this.secondNavOptions.find((el) => el.dictKey == this.formData.nav2Key)?.dictValue;
        this.formData.nav1Value = nav1Value;
        this.formData.nav2Value = nav2Value;
        const _loginState = Number(this.formData.loginState);
        const _status = Number(this.formData.status);
        const _ysbState = Number(this.formData.ysbState);
        const _zyState = Number(this.formData.zyState);
        this.saveLoading = true;
        miniComposeSaveOrUpdate({...this.formData,loginState: _loginState, status: _status, ysbState: _ysbState, zyState: _zyState }).then((res) => {
            this.$message.success("操作成功");
            this.dialogClose();
            this.getData();
        }).finally(() => {
            this.saveLoading = false;
        })
    })
  }


    dialogClose() {
        this.$nextTick(() => {
            this.fileList = [];
            this.dialogVisible = false;
            this.saveLoading = false;
        })
    }
}

</script>
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.cotent-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.cotent-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 101px);
}

.dialogInput {
    width: 260px
}

.dialog-footer{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.store-saas-name{
  color: $primary;
  margin-left: 5px;
}
.tag {
  margin-right: 10px;
  height: auto;
  max-width: 100%;
  line-height: 20px;
  padding: 6px 10px;
  span{
    white-space: normal;
  }
}
.disable-btn {
  color: #30a16b;
}
.enable-btn {
  color: #ff6700;
}

.tip{
    font-size: 12px;
    color: #80858C;
}
:deep(.el-input__inner) {
    text-align: left;
}



</style>